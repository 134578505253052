// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-projects-becomeorganized-js": () => import("./../../../src/pages/projects/becomeorganized.js" /* webpackChunkName: "component---src-pages-projects-becomeorganized-js" */),
  "component---src-pages-projects-childishgambino-js": () => import("./../../../src/pages/projects/childishgambino.js" /* webpackChunkName: "component---src-pages-projects-childishgambino-js" */),
  "component---src-pages-projects-focustimer-js": () => import("./../../../src/pages/projects/focustimer.js" /* webpackChunkName: "component---src-pages-projects-focustimer-js" */),
  "component---src-pages-projects-portfolio-js": () => import("./../../../src/pages/projects/portfolio.js" /* webpackChunkName: "component---src-pages-projects-portfolio-js" */),
  "component---src-pages-projects-sneakershop-js": () => import("./../../../src/pages/projects/sneakershop.js" /* webpackChunkName: "component---src-pages-projects-sneakershop-js" */),
  "component---src-templates-project-post-js": () => import("./../../../src/templates/project-post.js" /* webpackChunkName: "component---src-templates-project-post-js" */)
}

